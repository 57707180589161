import React from 'react';
import NewPersonForm from "../Components/NewPersonForm";

const CreatePersonPage = (props) => {
  return (
    <div>
      <NewPersonForm />
    </div>
  );
}

export default CreatePersonPage;